exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-artists-jsx": () => import("./../../../src/pages/artists.jsx" /* webpackChunkName: "component---src-pages-artists-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-radio-jsx": () => import("./../../../src/pages/radio.jsx" /* webpackChunkName: "component---src-pages-radio-jsx" */),
  "component---src-pages-releases-jsx": () => import("./../../../src/pages/releases.jsx" /* webpackChunkName: "component---src-pages-releases-jsx" */),
  "component---src-pages-templates-artist-page-jsx": () => import("./../../../src/pages/templates/artist-page.jsx" /* webpackChunkName: "component---src-pages-templates-artist-page-jsx" */),
  "component---src-pages-templates-video-page-jsx": () => import("./../../../src/pages/templates/video-page.jsx" /* webpackChunkName: "component---src-pages-templates-video-page-jsx" */)
}

